import { required, helpers } from '@vuelidate/validators'
/**
 *  Messages
 *  Este campo solo permite caracteres Númericos.
 *  Este campo es obligatorio.
 */

export default function ChallengesAddRules(){
    const rules = {
        name: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
        },
        description: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
        },
        limit_date: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
        },
        status: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
        },
    };

    return {
        rules
    }
}
