<template>
  <div>
    <bread-layout :items="breadcrumbLinks"></bread-layout>
    <div class="row">
      <AlertsPayment :nextPayments="getAuthUser?.client?.next_payment" />
    </div>
    <div class="row">
      <challenges-card
        v-for="(challenge, index) in challenges"
        :key="index"
        :challenge="challenge"
      >
        <template #actions>
          <a
            class="dropdown-item"
            href="#"
            data-target="#modalEvidences"
            data-toggle="modal"
            @click.prevent="add(challenge.id)"
            >Añadir evidencia</a
          >
        </template>
      </challenges-card>
    </div>

    <!-- Modals -->
    <teleport to="#modal-wrapper">
      <c-modal
        id="modalEvidences"
        route_name="/api/notes/update"
        :form="form"
        :title_modal="title_modal"
        :method_post="true"
        :btnSubmit="formHasErrors.length > 0"
        @onSubmit="submitForm()"
      >
        <template #modalBody>
          <div class="col-md-12">
            <div class="form-group" :class="{ error: v.images.$errors.length }">
              <label for="images">Evidencias *</label>
              <input
                type="file"
                name="images[]"
                ref="images"
                id="images"
                class="form-control"
                multiple
                accept="image/*"
                @change="getImages"
                required
              />
              <error-msg :errors="v.images.$errors"></error-msg>
            </div>
          </div>
        </template>
      </c-modal>
    </teleport>
    <!-- End Modals -->
  </div>
</template>

<script>
import AlertsPayment from "@/views/profile/AlertsPayment.vue";
import { useStore } from "vuex";
import { ref } from "vue-demi";
import { computed, onMounted } from "@vue/runtime-core";
import useVuelidate from "@vuelidate/core";
import BreadLayout from "../../layouts/BreadLayout.vue";
import ChallengesCard from "./ChallengesCard.vue";
import CModal from "../../components/modals/CModal.vue";
import useChallengesForm from "@/composables/challenges/useChallengesForm.js";
import ChallengesEvidencesRules from "@/rules/ChallengesEvidencesRules.js";
import ErrorMsg from "../../components/modals/ErrorMsg.vue";
import { addEvidencesChallengeService } from "@/services/challenges/challengesServices.js";
export default {
  components: {
    BreadLayout,
    ChallengesCard,
    CModal,
    ErrorMsg,
    AlertsPayment,
  },
  setup() {
    onMounted(() => {
      window.scrollTo( 0, 0 );
    });
    
    const breadcrumbLinks = ref([
      {
        text: "Retos",
        path: "/retos",
      },
    ]);

    // Obtiene los retos
    const { loading, getChallenges, data: challenges } = useChallengesForm();
    getChallenges();

    const form = ref({
      imageable_id: "",
      imageable_type: "App\\Models\\Challenge",
      images: [],
    });

    const images = ref(null);
    const title_modal = ref("");

    const add = (id) => {
      title_modal.value = "Añadir evidencia";
      form.value.images = [];
      form.value.imageable_id = id;
      images.value.value = "";
      v.value.$reset();
    };

    const getImages = (e) => {
      const images = e.target.files;
      for (const image of images) {
        form.value.images.push(image);
      }
    };

    const { rules } = ChallengesEvidencesRules(form);
    const v = useVuelidate(rules, form, { $autoDirty: true });

    const formHasErrors = computed(() => {
      return v.value.$silentErrors;
    });

    const submitForm = async () => {
      const isFormCorrect = await v.value.$validate();
      if (!isFormCorrect) return;

      const formData = new FormData();
      formData.append("imageable_id", form.value.imageable_id);
      formData.append("imageable_type", "App\\Models\\Challenge");
      for (const image of form.value.images) {
        formData.append("images[]", image);
      }

      const response = await addEvidencesChallengeService(formData);
      if (response.status === 200) {
        Swal.fire("Hecho", response.message, "success").then(() => {
          $("#modalEvidences").modal("toggle");
        });
      } else {
        Swal.fire("Error!", response.message, "error").then(() => {
          $("#modalEvidences").modal("toggle");
        });
      }
    };
    const store = useStore();

    return {
      getAuthUser: computed(() => store.state.User.user),
      breadcrumbLinks,
      loading,
      challenges,
      title_modal,
      form,
      submitForm,
      v,
      add,
      images,
      getImages,
      formHasErrors,
    };
  },
};
</script>

<style>
</style>
