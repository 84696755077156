import peticionHttp from "../peticionHttp";
const service = "api";

export const getChallengesListService = async () => {
    const response = await peticionHttp.get(`${service}/challenges`);
    return response;
};

export const addChallengeService = async (payload) => {
    const response = await peticionHttp.post(`${service}/challenges`, payload);
    return response;
};

export const getChallengeService = async (id) => {
    const response = await peticionHttp.get(`${service}/challenges/get/${id}`);
    return response;
};

export const editChallengeService = async (payload) => {
    const response = await peticionHttp.put(`${service}/challenges`, payload);
    return response;
};

export const deleteChallengeService = async (id) => {
    const response = await peticionHttp.delete(`${service}/challenges/${id}`);
    return response;
};

export const addEvidencesChallengeService = async (payload) => {
    const response = await peticionHttp.post(`${service}/challenges/evidences`, payload);
    return response;
};
