import { helpers, requiredIf } from '@vuelidate/validators'

export default function ChallengesAddRules(form){
    const rules = {
        images: {
            required: helpers.withMessage('Este campo es obligatorio.', requiredIf(function (){
                return form.value.images.length == 0
            })),
        }
    };

    return {
        rules
    }
}
