import { ref } from "@vue/reactivity";
import { getChallengesListService } from "../../services/challenges/challengesServices";
export default () => {
    const loading = ref(false);
    const data = ref([]);
    const getChallenges = async () => {
        NProgress.start()
        loading.value = true;
        const response = await getChallengesListService();
        if (response.status === 200) {
            data.value = response.data
        }else {
            Swal.fire("Error!", response.message, "error");
        }
        loading.value = false;
        NProgress.done()
    };
    return {
        loading,
        getChallenges,
        data,
    };
};
