<template>
    <div class="col-xl-4 col-lg-12 col-sm-12">
        <div class="card">
            <div class="card-header border-0 pb-0">
                <div class="d-flex justify-content-between align-items-center w-100">
                    <h2 class="card-title">{{ challenge.name }}</h2>
                    <div class="dropdown">
                        <button
                            type="button"
                            class="btn btn-primary light sharp"
                            data-toggle="dropdown"
                        >
                            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <circle fill="#000000" cx="5" cy="12" r="2" />
                                    <circle fill="#000000" cx="12" cy="12" r="2" />
                                    <circle fill="#000000" cx="19" cy="12" r="2" />
                                </g>
                            </svg>
                        </button>
                        <div class="dropdown-menu">
                            <slot name="actions"></slot>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body pb-0">
                <p v-text="challenge.description"></p>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex px-0 justify-content-between">
                        <strong>Estado</strong>
                        <span
                            :class="['badge light mb-0', `badge-${checkStatus(challenge.status).color}`]"
                        >{{ checkStatus(challenge.status).text }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                        <strong>Fecha limite</strong>
                        <span class="mb-0">{{ dateFormat(challenge.limit_date) }}</span>
                    </li>
                    <li class="list-group-item d-flex px-0 justify-content-between">
                        <strong>Dias restantes</strong>
                        <span
                            :class="`mb-0 badge badge-${getDeadlineColor(challenge.days_until_the_deadline)}`"
                        >{{ challenge.days_until_the_deadline }}</span>
                    </li>
                    <li v-show="challenge.comments">
                        <div class="py-2">
                            <strong>Comentarios</strong>
                        </div>
                        <div class="mb-2">{{ challenge.comments }}</div>
                    </li>
                </ul>
            </div>
            <div class="card-footer pt-0 pb-0 text-center">
                <div class="row">
                    <slot :challengeId="challenge.id"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useFormat from '@/composables/format/useFormat.js'
import useStatus from '@/composables/useStatus.js'
export default {
    props: {
        challenge: {
            type: Object,
        },
    },
    setup() {
        const { dateFormat } = useFormat()
        const { checkStatus } = useStatus()

        const getDeadlineColor = (deadLine) => {
            if (deadLine >= 30) return 'success'
            if (deadLine > 5 && deadLine <= 15) return 'warning'
            if (deadLine <= 5) return 'danger'
        }
        return {
            dateFormat,
            checkStatus,
            getDeadlineColor,
        }
    }
}
</script>

<style>
</style>
